import { useRef, useState } from "react"
import { Cell } from "./cell"
import { Modal } from "./modal"
import ReactSwitch from "react-switch"
import { createOrder } from "../api/api"
import Cookies from "js-cookie"
import { Oval } from "react-loader-spinner"
import { PhoneInput } from "./phoneInput"


export const Timetable = ({ schedule, minAge }) => {

    const [activeForm, setActiveForm] = useState(false)
    const [activeSuccess, setActiveSuccess] = useState(false)

    const [currentLesson, setCurrentLesson] = useState({})
    const [checked, setChecked] = useState(false)

    const [loading, setLoading] = useState(false)

    const clientInfo = localStorage.getItem('clientInfo') ? JSON.parse(localStorage.getItem('clientInfo')).personalInfo : {}

    const [name, setName] = useState(clientInfo?.fio?.split(' ')[0])
    const [surname, setSurname] = useState(clientInfo?.fio?.split(' ')[1])
    const [email, setEmail] = useState(clientInfo?.email)
    const [phoneNumber, setPhoneNumber] = useState(clientInfo?.tel)

    const [error, setError] = useState(false)

    const [childAge, setChildAge] = useState('')
    const send = async (e) => {
        e.preventDefault()
        if (error) return
        const regex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
        if (regex.test(e.target.phoneNumber.value)) {
            setLoading(true)
            const regExp = /[^0-9]/g
            const data = new FormData(e.target)
            data.set('phoneNumber', e.target.phoneNumber.value.replace(regExp, ''))
            const inputs = data.entries()
            const orderObj = {}
            for (let input of inputs) {
                orderObj[input[0]] = input[1]
            }
            orderObj.clientId = clientInfo.guid
            orderObj.lessonId = currentLesson.doc_ID
            orderObj.lessonDate = currentLesson.dateBeg
            if (checked) {
                orderObj.amount = childrens?.filter(el => el.ageGroup_High >= childAge).sort((a, b) => a.ageGroup_High - b.ageGroup_High)[0].cost || (adults?.cost + 'p.')
                orderObj.ageGroupCode = childrens?.filter(el => el.ageGroup_High >= childAge).sort((a, b) => a.ageGroup_High - b.ageGroup_High)[0].ageGroup_High || adults?.ageGroup_High
            } else {
                orderObj.amount = adults?.cost
            }

            const response = await createOrder(orderObj)
            if (response.success) {
                window.location.href = response.formURL
            }
            console.log(response)
        } else {
            alert('Введите корректный номер телефона')
        }

    }

    const adults = currentLesson?.prices?.find(el => el.ageGroup_High === 0)
    const childrens = currentLesson?.prices?.filter(el => el.ageGroup_High !== 0)
    const maxAge = childrens?.length ? childrens.sort((a, b) => b.ageGroup_High - a.ageGroup_High).at().ageGroup_High : 0
    console.log(adults)
    console.log(childrens)
    return (
        <>
            <Modal active={activeSuccess} setActive={setActiveSuccess} >
                <h2>Вы успешно записались!</h2>
            </Modal>
            <Modal active={activeForm} setActive={setActiveForm} >
                {
                    loading ?
                        <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'gap': '15px', 'width': '100%', 'flexWrap': 'wrap' }}>
                            <Oval
                                visible={true}
                                height="80"
                                width="80"
                                color="#ff7f00"
                                secondaryColor='#ff7f00'
                                ariaLabel="loading"
                            />
                        </div>
                        :
                        <>

                            <h2 style={{ fontSize: '28px' }}>Записаться на занятие к инструктору</h2>
                            <form onSubmit={send}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                    {
                                        childrens?.length > 0 &&
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                            <span style={{ color: 'black' }}>Записываю ребенка (до {maxAge} лет включительно)</span>
                                            <ReactSwitch checked={checked} onChange={() => setChecked(!checked)} onColor="#fc9a40" checkedIcon={false} uncheckedIcon={false} />
                                        </div>
                                    }
                                    <span style={{ textAlign: 'left', fontWeight: '500', color: 'black', fontSize: '22px' }}>Заполните данные {checked ? 'родителя' : ''}:</span>
                                    <input type="text" placeholder="Фамилия*" name="surname" value={name} onChange={(e) => setName(e.target.value)} required />
                                    <input type="text" placeholder="Имя*" required name="firstname" value={surname} onChange={(e) => setSurname(e.target.value)} />
                                    <input type="text" placeholder="Отчество" name="middlename" />
                                    <PhoneInput value={phoneNumber} onChange={(e) => {
                                        setPhoneNumber(e.target.value)
                                    }} />
                                    <input type="email" placeholder="Email*" required name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <select style={{ display: 'none' }} required name="notifyService">
                                        <option value="mail">Почта</option>
                                        <option value="whatsapp">WhatsApp</option>
                                    </select>
                                    {
                                        checked ?
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                                <span style={{ textAlign: 'left', fontWeight: '500', color: 'black', fontSize: '22px' }}>Заполните данные ребенка:</span>
                                                <input type="text" placeholder="Имя ребенка*" name="childrenName" required />
                                                <input type="text" name="childrenSurname" placeholder="Фамилия ребенка*" required />
                                                <input onChange={(e) => {
                                                    setChildAge(e.target.value)
                                                    setError('')
                                                    if (e.target.value > maxAge) {
                                                        setError('Максимальный возраст ребенка ' + maxAge)
                                                    } else if (e.target.value < minAge) {
                                                        setError('Минимальный возраст ребенка ' + minAge)
                                                    }
                                                    if (e.target.value.length == 0) setError('')
                                                    console.log(e.target.value)
                                                }}
                                                    value={childAge} type="number" placeholder="Возраст ребенка*" name="childrenAge" required />
                                                {error && <span style={{ color: 'red', textAlign: 'left' }}>{error}</span>}
                                            </div>
                                            : null
                                    }
                                    <div style={{ border: '2px solid #fc9a40', borderRadius: '20px', padding: '20px', textAlign: 'left' }}>
                                        <span style={{ fontWeight: '500', color: 'black', fontSize: '16px' }}>
                                            Вы записываетесь на {currentLesson?.exerciseSort?.toLowerCase()} на {currentLesson?.kindOfSport?.toLowerCase()} {currentLesson?.dateBeg?.split(' ')[0]} c {currentLesson?.dateBeg?.split(' ')[1]}<br />
                                            Инструктор: {currentLesson?.trainer_Name}<br />
                                            Итого: {
                                                checked ?
                                                    (
                                                        childAge <= maxAge
                                                            ?
                                                            childrens?.filter(el => el.ageGroup_High >= childAge).sort((a, b) => a.ageGroup_High - b.ageGroup_High)[0].cost + 'p.'
                                                            :
                                                            (adults?.cost + 'p.')
                                                    ) :
                                                    adults?.cost + 'p.'
                                            }
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <input id="agreement" type="checkbox" required style={{ marginRight: '10px' }} />
                                        </div>
                                        <span style={{ textAlign: 'left' }}>
                                            Согласен с обработкой персональных данных в соответствии с <a href="https://yes35.ru/privacy-policy.html" style={{ color: '#fc9a40' }}>политикой конфиденциальности</a>
                                        </span>
                                    </div>
                                    <button type="submit">Записаться</button>
                                </div>
                            </form>
                        </>
                }
            </Modal>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 15, width: '100%', flexWrap: 'wrap' }}>
                {schedule ? schedule.map(el => (
                    <Cell lesson={el} key={el.doc_ID + 1} active={activeForm} setActive={setActiveForm} setCurrentLesson={setCurrentLesson} />
                ))
                    : <p style={{color: 'black'}}>На сегодня нет записи</p>}
            </div>
        </>
    )
}